import { trpc } from "./trpc";

export function useOrganizations() {
  const { data, ...rest } = trpc.organization.getAll.useQuery(undefined);

  return {
    data: data ?? [],
    ...rest,
  };
}
