import { FeatureFlag } from "api/src/featureFlags";
import { useFeatureFlagPayload } from "posthog-js/react";
import { useIsFeatureEnabled } from "./useIsFeatureEnabled";

export function useOrgLogo({ organizationId }: { organizationId?: string }) {
  const isUsingOrgLogo = useIsFeatureEnabled(FeatureFlag.UseOrgLogo);
  const orgLogoMap = useFeatureFlagPayload(FeatureFlag.UseOrgLogo) as
    | Record<string, string>
    | undefined;

  if (!organizationId || !isUsingOrgLogo || !orgLogoMap) {
    return null;
  }

  return typeof orgLogoMap[organizationId] !== "undefined"
    ? orgLogoMap[organizationId]
    : null;
}
