import {
  AspectRatio,
  Box,
  Center,
  Group,
  Modal,
  Skeleton,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useStoryblok } from "@storyblok/react";
import { IconConfetti } from "@tabler/icons-react";
import ReactPlayer from "react-player";
import type { WelcomeVideoStoryblok } from "../../storyblok-types";
import classes from "./FirstTimeVideo.module.css";

function FirstTimePopupContent() {
  const story = useStoryblok("welcome-video-popup", {
    version: "published",
  });

  const isPortrait = useMediaQuery("screen and (orientation: portrait)");
  const content = story.content as WelcomeVideoStoryblok | null;

  const desktopVideo = content?.desktopVideo.filename;
  const mobileVideo = content?.mobileVideo?.filename || desktopVideo;
  const videoSrc = isPortrait ? mobileVideo : desktopVideo;

  if (!content) {
    return (
      <Center p="xl">
        <Skeleton w="100%" h="100%" mih={380} />
      </Center>
    );
  }

  return (
    <Box>
      {content.tagline && (
        <Text size="sm" mb="sm">
          {content.tagline}
        </Text>
      )}
      <ReactPlayer
        playing
        loop
        playsinline
        wrapper={({ children }) => {
          return (
            <AspectRatio
              h={isPortrait ? 480 : "100%"}
              ratio={isPortrait ? 2 / 3 : 16 / 9}
              className={classes.welcomeVideoWrapper}
            >
              {children}
            </AspectRatio>
          );
        }}
        url={videoSrc}
        stopOnUnmount
      />
    </Box>
  );
}

export function FirstTimeVideoModal({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: () => void;
}) {
  if (!opened) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      size="xl"
      centered
      title={
        <Group gap="xs">
          <ThemeIcon size="lg" variant="transparent" color="dark">
            <IconConfetti />
          </ThemeIcon>
          <Text size="lg" fw={600} span>
            Welcome to NOAN!
          </Text>
        </Group>
      }
    >
      <Center>{opened && <FirstTimePopupContent />}</Center>
    </Modal>
  );
}
