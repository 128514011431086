type NoanApplicationErrorContext = Record<string, unknown> | string;
export enum NoanError {
  Invariant = "Invariant error",
  InvalidPromptMissingUserItem = "Invalid generator prompt - missing user item",
  NotLoggedIn = "User not logged in.",
}

export class NoanApplicationError extends Error {
  constructor(
    public readonly errorType: NoanError,
    public readonly ctx: NoanApplicationErrorContext,
  ) {
    super(errorType);
  }

  toString() {
    return `${this.errorType}: ${JSON.stringify(this.ctx)}`;
  }
}

export function generateNoanError(
  errorType: NoanError,
  additionalContext: NoanApplicationErrorContext = {},
) {
  return new NoanApplicationError(errorType, additionalContext);
}

export function isNoanError(error: Error): error is NoanApplicationError {
  return (error as NoanApplicationError).errorType != null;
}
