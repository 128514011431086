import { trpc } from "../utils/trpc";

/**
 * Thin wrappers around react-query/trpc mutations for Identity.
 */
export function useIdentityMutation() {
  const utils = trpc.useUtils();

  const { mutate: saveIdentities, isLoading: isLoadingSaveIdentities } =
    trpc.identity.inviteUsersAndSaveIdentities.useMutation({
      onSettled() {
        utils.organization.getById.invalidate();
        utils.account.getBilling.invalidate();
      },
    });

  const { mutate: updateIdentity, isLoading: isLoadingUpdateIdentity } =
    trpc.identity.update.useMutation({
      onSettled() {
        utils.organization.getById.invalidate();
        utils.identity.invalidate();
      },
    });

  const { mutate: deleteIdentity, isLoading: isDeletingIdentity } =
    trpc.identity.delete.useMutation({
      onSettled() {
        utils.organization.getById.invalidate();
        utils.account.getBilling.invalidate();
      },
    });

  return {
    saveIdentities,
    isLoadingSaveIdentities,
    updateIdentity,
    isLoadingUpdateIdentity,
    deleteIdentity,
    isDeletingIdentity,
  };
}
