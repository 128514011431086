import { useContext } from "react";
import { AuthContext, AuthOptionalContext } from "./AuthContext";

export function useAuthContext() {
  const ctx = useContext(AuthContext);

  if (ctx == null) {
    throw new Error("useAuthContext must be used inside AuthContext.Provider");
  }

  return ctx;
}

export function useOptionalAuthContext() {
  const ctx = useContext(AuthOptionalContext);

  if (ctx == null) {
    throw new Error(
      "useOptionalAuthContext must be used inside AuthOptionalContext.Provider",
    );
  }

  return ctx;
}
