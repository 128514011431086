import type { FeatureFlag } from "api/src/featureFlags";
import { usePostHog } from "posthog-js/react";
import { isDeployed } from "../config";

/**
 * Wrapper around Posthog that allows enabling feature flags by default
 * in development.
 */
export function useIsFeatureEnabled(featureFlag: FeatureFlag | string) {
  const posthog = usePostHog();

  if (!isDeployed) {
    return true;
  }

  return posthog.isFeatureEnabled(featureFlag);
}
