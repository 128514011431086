/**
 * Extended version of mantine's NavLink with support for React-Router, and a
 * couple of convenience changes.
 */
import {
  NavLink as MantineNavLink,
  ThemeIcon,
  type NavLinkProps,
} from "@mantine/core";
import { NavLink as RouterNavLink } from "react-router-dom";
import classes from "./NavLink.module.css";

export function NavLink(
  props: NavLinkProps & {
    to: string;
    icon?: JSX.Element;
  },
) {
  const { label, to, icon, ...linkProps } = props;

  return (
    <MantineNavLink
      {...linkProps}
      label={label}
      href={to}
      variant="filled"
      leftSection={
        <ThemeIcon variant="transparent" color="currentColor" size="lg">
          {icon}
        </ThemeIcon>
      }
      classNames={{
        root: classes.navLink,
        section: classes.navLinkSection,
        body: classes.navLinkBody,
        label: classes.navLinkLabel,
      }}
      renderRoot={(rootProps) => (
        <RouterNavLink {...rootProps} label={label} to={to} />
      )}
    />
  );
}
