/**
 * Mantine style imports:
 */
import "@fontsource-variable/inter";
import "@fontsource/syncopate/700.css";
import "@mantine/carousel/styles.css";
import "@mantine/charts/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";

import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { AppContainer } from "./App.tsx";
import { isDeployed, sentryDsn } from "./config.ts";

/**
 * Global css:
 */
import "./index.css";

Sentry.init({
  dsn: sentryDsn,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/beta.getnoan\.com/,
        /^https:\/\/app.getnoan\.com/,
      ],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  enabled: isDeployed,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
);
