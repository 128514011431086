export type LayoutVariant = "default" | "sidebar" | "minimal";

export const navigationHeaderHeights: Record<LayoutVariant, number> = {
  default: 40,
  sidebar: 0,
  minimal: 0,
} as const;

export const topHeaderHeights = {
  mobile: 80,
  desktop: 30,
  minimal: 0,
} as const;
