/* eslint-disable react-refresh/only-export-components */
import { lazy } from "react";
import { Navigate, type RouteObject } from "react-router-dom";
import { ErrorPage } from "./ErrorPage";
import { AuthedLayout, Layout } from "./Layout";

const Account = lazy(() =>
  import("./Account").then((module) => ({ default: module.Account })),
);
const Insights = lazy(() =>
  import("./Insights/Insights").then((module) => ({
    default: module.Insights,
  })),
);
const Login = lazy(() =>
  import("./Auth").then((module) => ({ default: module.Login })),
);
const Signup = lazy(() =>
  import("./Auth").then((module) => ({ default: module.Signup })),
);
const Dashboard = lazy(() =>
  import("./Dashboard").then((module) => ({ default: module.Dashboard })),
);
const Build = lazy(() =>
  import("./Build").then((module) => ({ default: module.Build })),
);
const OnboardingPage = lazy(() =>
  import("./Onboarding").then((module) => ({ default: module.OnboardingPage })),
);
const AllStacksPage = lazy(() =>
  import("./Build/AllStacksPage").then((module) => ({
    default: module.AllStacksPage,
  })),
);
const BlockPage = lazy(() =>
  import("./Build/BlockPage").then((module) => ({ default: module.BlockPage })),
);
const StackPage = lazy(() =>
  import("./Build/StackPage").then((module) => ({ default: module.StackPage })),
);
const Create = lazy(() =>
  import("./Create").then((module) => ({ default: module.Create })),
);
const Convo = lazy(() =>
  import("./Create/Convo").then((module) => ({ default: module.Convo })),
);
const ConvoList = lazy(() =>
  import("./Create/ConvoList").then((module) => ({
    default: module.ConvoList,
  })),
);
const IdeasPage = lazy(() =>
  import("./Ideas").then((module) => ({ default: module.IdeasPage })),
);
const InsightsReportBuilder = lazy(() =>
  import("./Insights/ReportBuilder").then((module) => ({
    default: module.InsightsReportBuilder,
  })),
);
const ReportResult = lazy(() =>
  import("./Insights/ReportResult").then((module) => ({
    default: module.ReportResult,
  })),
);
const Subscribe = lazy(() =>
  import("./Subscribe").then((module) => ({ default: module.Subscribe })),
);

const Workspace = lazy(() =>
  import("./Workspace").then((module) => ({ default: module.WorkspacePage })),
);
const Sales = lazy(() =>
  import("./Sales").then((module) => ({
    default: module.Sales,
  })),
);

export const routeDefinitions: RouteObject[] = [
  {
    // Optional authed routes without navigation
    element: <Layout withPadding={false} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/join",
        element: <Signup />,
      },
      {
        path: "/404",
        element: <ErrorPage code={404} />,
      },
    ],
  },
  {
    // Required authed routes with default navigation
    element: <AuthedLayout variant="sidebar" />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/subscribe",
        element: <Subscribe />,
      },
      {
        path: "/account/:section?/:subSection?",
        element: <Account />,
      },
      {
        path: "/strategy",
        element: <Navigate to="/build" />,
      },
      {
        path: "/build",
        element: <Build />,
      },
      {
        path: "/build/stacks",
        element: <AllStacksPage />,
      },
      {
        path: "/build/b/:blockPath",
        element: <BlockPage />,
      },
      {
        path: "/build/s/:stackSlug",
        element: <StackPage />,
      },
      {
        path: "/create",
        element: <Create />,
      },
      {
        path: "/create/c/:convoId",
        element: <Convo />,
      },
      {
        path: "/create/all",
        element: <ConvoList />,
      },
      {
        path: "/ideas",
        element: <IdeasPage />,
      },
      {
        path: "/workspace",
        element: <Workspace />,
      },
      {
        path: "/sales",
        element: <Sales />,
      },
      {
        path: "/insights",
        children: [
          {
            path: "",
            element: <Insights />,
          },
          {
            path: "/insights/reports",
            element: <InsightsReportBuilder />,
          },
          {
            path: "/insights/reports/:reportId/results",
            element: <ReportResult />,
          },
        ],
      },
    ],
  },
  {
    element: <AuthedLayout variant="minimal" />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/welcome",
        element: <OnboardingPage />,
      },
    ],
  },
];
