import { useNetwork } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconAccessPointOff } from "@tabler/icons-react";
import { useEffect } from "react";

const notificationId = "network-warning";

export function ConnectionStateMonitor() {
  const { online: isOnline } = useNetwork();

  useEffect(() => {
    if (!isOnline) {
      notifications.show({
        id: notificationId,
        color: "red",
        icon: <IconAccessPointOff className="animate-pulse" size={18} />,
        title: "No internet connection",
        message: "Some features may be unavailable until you are back online",
        autoClose: false,
        withCloseButton: false,
      });
    } else {
      notifications.hide(notificationId);
    }

    return () => {
      // Ensure the notification gets cleaned up on unmount:
      notifications.hide(notificationId);
    };
  }, [isOnline]);

  return null;
}
